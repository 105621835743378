import Vue from 'vue';
import Dayjs from 'dayjs';

export default {
    getEventById(eventId) {
        return Vue.prototype.$axiosMainClient.get(
            `/Events/getEvent?eventId=${eventId}`
        );
    },
    getCalendars(params) {
        //window.console.log(Vue.prototype.$axiosMainClient.defaults.headers.common['Authorization']);
        return Vue.prototype.$axiosMainClient.get('/calendar' + params);
    },
    getAllProgress() {
        return Vue.prototype.$axiosMainClient.get('/Progress/');
    },
    getAllProgressListView() {
        return Vue.prototype.$axiosMainClient.get('/Progress/listView');
    },
    getAllProgressConfig() {
        return Vue.prototype.$axiosMainClient.get(
            '/Progress/getAllProgressConfig'
        );
    },
    getProgressCapacities(stepIds) {
        //array of id
        return Vue.prototype.$axiosMainClient.post(
            'v1.1/Progress/capacities',
            stepIds
        );
    },
    getWorkOrderFilterString(filter) {
        var workOrderIdPublicFilter =
            filter.workOrderIdPublic == null
                ? ''
                : '&workOrderIdPublic=' + filter.workOrderIdPublic;
        var workOrderTypeIdPublic =
            filter.workOrderTypeIdPublic == null
                ? ''
                : '&workOrderTypeIdPublic=' + filter.workOrderTypeIdPublic;
        var workOrderName =
            filter.workOrderName == null
                ? ''
                : '&workOrderName=' + filter.workOrderName;

        return workOrderIdPublicFilter + workOrderTypeIdPublic + workOrderName;
    },
    getProgressAppointments(
        progressId,
        start = null,
        end = null,
        filter = null
    ) {
        let startParam = start == null ? '' : '&start=' + start;
        let endParam = end == null ? '' : '&end=' + end;

        var filterString = null;
        if (filter != null) {
            filterString = this.getWorkOrderFilterString(filter);
        }

        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getProgressAppointments?progressId=' +
                progressId +
                startParam +
                endParam +
                filterString
        );
    },
    getAllProgressAppointments() {
        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getAllProgressAppointments'
        );
    },
    getShippers() {
        return Vue.prototype.$axiosMainClient.get('/Delivery/getShippers');
    },
    getDeliveryAppointments(
        shipperIdPublic,
        start = null,
        end = null,
        filter = null
    ) {
        let shipperParam =
            shipperIdPublic == null || shipperIdPublic == 0
                ? ''
                : 'shipperIdPublic=' + shipperIdPublic;
        let startParam = start == null ? '' : '&start=' + start;
        let endParam = end == null ? '' : '&end=' + end;

        var filterString = null;
        if (filter != null) {
            filterString = this.getWorkOrderFilterString(filter);
        }

        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getDeliveryAppointments?' +
                shipperParam +
                startParam +
                endParam +
                filterString
        );
    },
    getAllDeliveryAppointments() {
        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getAllDeliveryAppointments'
        );
    },
    getInstallers() {
        return Vue.prototype.$axiosMainClient.get('/Installers/getInstallers');
    },
    getInstaller(idPublic) {
        return Vue.prototype.$axiosMainClient.get('/Installers/' + idPublic);
    },
    getInstallationAppointments(
        installerIdPublic,
        start = null,
        end = null,
        filter = null
    ) {
        let installerParam =
            installerIdPublic == null || installerIdPublic == 0
                ? ''
                : 'installerIdPublic=' + installerIdPublic;
        let startParam = start == null ? '' : '&start=' + start;
        let endParam = end == null ? '' : '&end=' + end;

        var filterString = null;
        if (filter != null) {
            filterString = this.getWorkOrderFilterString(filter);
        }

        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getInstallationAppointments?' +
                installerParam +
                startParam +
                endParam +
                filterString
        );
    },
    getInstallationEvents(installerIds, start = null, end = null, filterModel) {
        let search = filterModel.workOrderName;

        let installers = installerIds.map((x) => {
            return x.id.split('.').length === 2 ? x.id.split('.')[1] : x.id;
        });
        return Vue.prototype.$axiosMainClient.post(
            `Events?start=${start}&end=${end}${
                search ? '&search=' + search : ''
            }&userevent=false`,
            {
                installerIds: installers,
                steps: [],
                shipperIds: [],
                workOrderTypeIds: filterModel.workOrderTypeIds,
            }
        );
    },
    getProductionEvents(start = null, end = null, filterModel) {
        let search = filterModel.workOrderName;

        return Vue.prototype.$axiosMainClient.post(
            `Events?start=${start}&end=${end}&production=true${
                search ? '&search=' + search : ''
            }&userevent=false`,
            {
                workOrderTypeIds: filterModel.workOrderTypeIds,
            }
        );
    },
    getUserEvents(start = null, end = null, steps, installerIds, shipperIds) {
        return Vue.prototype.$axiosMainClient.post(
            `Events?start=${start}&end=${end}&userevent=true`,
            {
                steps: steps,
                installerIds: installerIds,
                shipperIds: shipperIds,
            }
        );
    },
    getProgressEvents(steps, start = null, end = null, filterModel) {
        let search = filterModel.workOrderName;
        let stepRequests = steps.map((x) => {
            return {
                id: x.id,
                stepUserIds: x.children.map((c) => {
                    return c.id.split('.').length === 2
                        ? c.id.split('.')[1]
                        : c.id;
                }),
            };
        });
        return Vue.prototype.$axiosMainClient.post(
            `Events?start=${start}&end=${end}${
                search ? '&search=' + search : ''
            }&userevent=false`,
            {
                steps: stepRequests,
                installerIds: [],
                shipperIds: [],
                workOrderTypeIds: filterModel.workOrderTypeIds,
            }
        );
    },
    getDeliveryEvents(shipperIds, start = null, end = null, filterModel) {
        let search = filterModel.workOrderName;
        let shippers = shipperIds.map((x) => {
            return x.id.split('.').length === 2 ? x.id.split('.')[1] : x.id;
        });
        return Vue.prototype.$axiosMainClient.post(
            `Events?start=${start}&end=${end}${
                search ? '&search=' + search : ''
            }&userevent=false`,
            {
                steps: [],
                installerIds: [],
                shipperIds: shippers,
                workOrderTypeIds: filterModel.workOrderTypeIds,
            }
        );
    },
    getAllInstallationAppointments() {
        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getAllInstallationAppointments'
        );
    },
    //Obsolete?
    setAppointmentDate(appointmentId, startDate) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setAppointmentDate/' + appointmentId,
            {
                StartDate: startDate,
            }
        );
    },
    createProgress(progressModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Progress/createProgress',
            progressModel
        );
    },
    updateProgressOrder(progressOrder) {
        return Vue.prototype.$axiosMainClient.post(
            'Progress/updateProgressOrder',
            progressOrder
        );
    },
    updateProgress(progressModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Progress/updateProgress',
            progressModel
        );
    },
    getProgressConfig(progressId) {
        return Vue.prototype.$axiosMainClient.get(
            'Progress/getProgressConfig/' + progressId
        );
    },
    softDeleteProgress(progressId) {
        return Vue.prototype.$axiosMainClient.post(
            'Progress/softDeleteProgress?progressId=' + progressId
        );
    },
    createTemplate(templateModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Template/createTemplate',
            templateModel
        );
    },
    updateTemplate(templateModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Template/updateTemplate',
            templateModel
        );
    },
    getAllTemplatesList() {
        return Vue.prototype.$axiosMainClient.get(
            'Template/getAllTemplates/list'
        );
    },
    getTemplateDetail(templateIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'Template/getTemplateDetail?templateIdPublic=' + templateIdPublic
        );
    },
    deleteTemplate(templateIdPublic) {
        return Vue.prototype.$axiosMainClient.post(
            'Template/deleteTemplate?templateIdPublic=' + templateIdPublic
        );
    },
    getAllJobTypes() {
        return Vue.prototype.$axiosMainClient.get('JobType/getAllJobTypes');
    },
    createJobType(name) {
        return Vue.prototype.$axiosMainClient.post('JobType/createJobType', {
            name: name,
        });
    },
    deleteJobType(jobTypeIdPublic) {
        return Vue.prototype.$axiosMainClient.post(
            'JobType/deleteJobType?idPublic=' + jobTypeIdPublic
        );
    },
    updateJobType(jobType) {
        return Vue.prototype.$axiosMainClient.post(
            'JobType/updateJobType',
            jobType
        );
    },
    getAllCapacityTypes() {
        return Vue.prototype.$axiosMainClient.get(
            'Capacity/getAllCapacityTypes'
        );
    },
    getAllUnitTypes() {
        return Vue.prototype.$axiosMainClient.get('UnitType');
    },
    createInstaller(installerModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Installers/createInstaller',
            installerModel
        );
    },
    deleteInstaller(installerIdPublic) {
        return Vue.prototype.$axiosMainClient.post(
            'Installers/deleteInstaller?installerIdPublic=' + installerIdPublic
        );
    },
    updateInstaller(installerModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Installers/updateInstaller',
            installerModel
        );
    },
    createShipper(shipperModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Delivery/createShipper',
            shipperModel
        );
    },
    deleteShipper(shipperIdPublic) {
        return Vue.prototype.$axiosMainClient.post(
            'Delivery/deleteShipper?shipperIdPublic=' + shipperIdPublic
        );
    },
    updateShipper(shipperModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Delivery/updateShipper',
            shipperModel
        );
    },
    generateProgressAppointmentsReviewList(workOrderEventInfoModel) {
        var templateIdPublic =
            'templateIdPublic=' + workOrderEventInfoModel.templateIdPublic;
        var jobTypeIdPublic =
            workOrderEventInfoModel.jobTypeIdPublic == null
                ? ''
                : 'jobTypeIdPublic=' + workOrderEventInfoModel.jobTypeIdPublic;

        var param = templateIdPublic + '&' + jobTypeIdPublic;

        return Vue.prototype.$axiosMainClient.post(
            'Appointment/generateProgressAppointmentsReviewList?' + param,
            workOrderEventInfoModel
        );
    },
    generateCopyProgressAppointmentsReviewList(
        workOrderEventInfoModel,
        copyWorkOrderIdPublic
    ) {
        var templateIdPublic =
            'templateIdPublic=' + workOrderEventInfoModel.templateIdPublic;
        var jobTypeIdPublic =
            workOrderEventInfoModel.jobType == null
                ? ''
                : 'jobTypeIdPublic=' + workOrderEventInfoModel.jobTypeIdPublic;

        var param = templateIdPublic + '&' + jobTypeIdPublic;

        return Vue.prototype.$axiosMainClient.post(
            'Appointment/generateCopyProgressAppointmentsReviewList/' +
                copyWorkOrderIdPublic +
                '?' +
                param,
            workOrderEventInfoModel
        );
    },
    regenerateProgressAppointmentsReviewList(
        workOrderEventInfoModel,
        appointmentsReviewList
    ) {
        var templateIdPublic =
            'templateIdPublic=' + workOrderEventInfoModel.templateIdPublic;
        var jobTypeIdPublic =
            workOrderEventInfoModel.jobType == null
                ? ''
                : 'jobTypeIdPublic=' + workOrderEventInfoModel.jobTypeIdPublic;

        var param = templateIdPublic + '&' + jobTypeIdPublic;

        return Vue.prototype.$axiosMainClient.post(
            'Appointment/regenerateProgressAppointmentsReviewList?' + param,
            {
                workOrderReviewListModel: workOrderEventInfoModel,
                progressAppointmentReviewDtos: appointmentsReviewList,
            }
        );
    },
    createWorkOrder(workOrderWithReviewListModel) {
        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/createWorkOrder',
            workOrderWithReviewListModel
        );
    },
    getAllProgressEventsByWorkOrderIdPublic(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getAllProgressEventsByWorkOrderIdPublic?workOrderIdPublic=' +
                workOrderIdPublic
        );
    },
    checkIfBrokenLink(workOrderIdPublic, type) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/checkIfBrokenLink/' +
                workOrderIdPublic +
                '?type=' +
                type.replace('Event', '')
        );
    },
    setReferenceAppointmentDate(
        type,
        appointmentId,
        startDate,
        endDate,
        allDay,
        moveBrokenLink,
        isMainEvent
    ) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setReferenceAppointmentDate',
            {
                type,
                appointmentId,
                startDate,
                endDate,
                allDay,
                moveBrokenLink,
                isMainEvent,
            }
        );
    },
    setReferenceAppointmentsDate(dataList) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setReferenceAppointmentsDate',
            dataList
        );
    },
    setProgressAppointmentDate(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setProgressAppointmentDate',
            model
        );
    },
    updateInstallationAppointmentDetails(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/updateInstallationAppointmentDetails',
            model
        );
    },
    updateDeliveryAppointmentDetails(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/updateDeliveryAppointmentDetails',
            model
        );
    },
    updateReferenceAppointmentDetails(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/updateReferenceAppointmentDetails',
            model
        );
    },
    duplicateInstallationAppointmentDetails(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/duplicateInstallationAppointmentDetails',
            model
        );
    },
    updateUserAppointmentDetails(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/updateUserAppointmentDetails',
            model
        );
    },
    updateProgressAppointmentDetails(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/updateProgressAppointmentDetails',
            model
        );
    },
    SetAppointmentEndDate(appointmentId, endDate) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/SetAppointmentEndDate',
            {
                Id: appointmentId,
                endDate,
            }
        );
    },
    findWorkOrderByName(search) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/findWorkOrderByName?search=' + search
        );
    },
    getLaterProgressAppointments(workOrderIdPublic, progressId) {
        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getLaterProgressAppointments?workOrderIdPublic=' +
                workOrderIdPublic +
                '&progressId=' +
                progressId
        );
    },
    getLaterProgressAppointmentsV1_1(workOrderIdPublic, stepId) {
        return Vue.prototype.$axiosMainClient.get(
            'v1.1/Appointment/getLaterProgressAppointments?workOrderIdPublic=' +
                workOrderIdPublic +
                '&stepId=' +
                stepId
        );
    },
    getWorkOrderProgressAppointments(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrderProgressAppointments?workOrderIdPublic=' +
                workOrderIdPublic
        );
    },
    completeProgressAppointment(
        progressAppointmentId,
        completePreviousProgress
    ) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/completeProgressAppointmentV2',
            {
                progressAppointmentId,
                completePreviousProgress,
            }
        );
    },
    startProgressAppointment(progressAppointmentId) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/startProgressAppointmentV2?progressAppointmentIdPublic=' +
                progressAppointmentId
        );
    },
    CreateUserAppointment(userAppointmentModel) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/createUserAppointment',
            userAppointmentModel
        );
    },
    setUserAppointmentDate(userAppointmentId, startDate, endDate, allDay) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setUserAppointmentDate',
            {
                userAppointmentId,
                startDate,
                endDate,
                allDay,
            }
        );
    },
    getProductionAppointments(start = null, end = null, filter = null) {
        let startParam = start == null ? '' : '&start=' + start;
        let endParam = end == null ? '' : '&end=' + end;

        var filterString = null;
        if (filter != null) {
            filterString = this.getWorkOrderFilterString(filter);
        }

        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getProductionAppointments?' +
                startParam +
                endParam +
                filterString
        );
    },
    getProgressCalendarConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'CalendarConfig/getProgressCalendarConfig'
        );
    },
    getShippersCalendarConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'CalendarConfig/getShippersCalendarConfig'
        );
    },
    getInstallersCalendarConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'CalendarConfig/getInstallersCalendarConfig'
        );
    },
    getProductionCalendarConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'CalendarConfig/getProductionCalendarConfig'
        );
    },
    updateCalendarItemsConfig(config) {
        return Vue.prototype.$axiosMainClient.post(
            'CalendarConfig/updateCalendarItemsConfig',
            config
        );
    },
    getUsers(filter = null, page = null, pageSize = null) {
        var filterString =
            filter == null || filter == '' || typeof filter == 'undefined'
                ? ''
                : 'filter=' + filter;
        filterString =
            page == null ? filterString : filterString + '&page=' + page;
        filterString =
            pageSize == null
                ? filterString
                : filterString + '&pageSize=' + pageSize;

        return Vue.prototype.$axiosMainClient.get(
            'Users/getUsers?' + filterString
        );
    },
    getUserConfig(userId) {
        return Vue.prototype.$axiosMainClient.get(
            'Users/getUserConfig?userId=' + userId
        );
    },
    saveUserConfig(userConfig) {
        return Vue.prototype.$axiosMainClient.post(
            'Users/saveUserConfig',
            userConfig
        );
    },
    getUserNotifications(length) {
        return Vue.prototype.$axiosMainClient.get(
            'Notifications?length=' + length
        );
    },
    setUserNotificationsNotNew(length) {
        return Vue.prototype.$axiosMainClient.get(
            'Notifications/setnotnew?length=' + length
        );
    },
    getUserNewNotification() {
        return Vue.prototype.$axiosMainClient.get('Notifications/new');
    },
    setUsernotificationAllRead() {
        return Vue.prototype.$axiosMainClient.get('Notifications/setallread');
    },
    setUserNotificationRead(notificationid) {
        return Vue.prototype.$axiosMainClient.get(
            'Notifications/setread?userNotificationId=' + notificationid
        );
    },
    deleteWorkOrder(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.delete(
            'WorkOrder?workOrderIdPublic=' + workOrderIdPublic
        );
    },
    getEmailConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'CalendarConfig/getEmailConfig'
        );
    },
    SetDeliveryAppointmentEndDate(eventId, endDate) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setDeliveryAppointmentEndDate',
            {
                Id: eventId,
                endDate: endDate,
            }
        );
    },
    SetInstallationAppointmentEndDate(eventId, endDate) {
        return Vue.prototype.$axiosMainClient.post(
            'Appointment/setInstallationAppointmentEndDate',
            {
                Id: eventId,
                endDate: endDate,
            }
        );
    },
    setBlockerEventEndDate(eventId, endDate) {
        return Vue.prototype.$axiosMainClient.patch('blockerevents/enddate', {
            Id: eventId,
            endDate: endDate,
        });
    },
    GetWorkOrderByIdPublic(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrderByIdPublic?idPublic=' + workOrderIdPublic
        );
    },
    getWorkOrderByIdPublicNoWorkOrderSteps(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrderByIdPublicNoWorkOrderSteps?idPublic=' +
                workOrderIdPublic
        );
    },
    getWorkOrdersValuesByIdPublic(workOrdersIdPublic, filter = null) {
        let queryString = '';

        if (filter != null) {
            queryString = this.getQueryString(filter);
        }

        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/getWorkOrdersValuesByIdPublic?' + queryString,
            workOrdersIdPublic
        );
    },
    getWorkOrderStepValues(workOrderStepIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrderStepValues?idPublic=' + workOrderStepIdPublic
        );
    },
    getWorkOrderStepsValues(workOrderStepsIdPublic, filter = null) {
        let queryString = '';

        if (filter != null) {
            queryString = this.getQueryString(filter);
        }

        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/getWorkOrderStepsValues?' + queryString,
            workOrderStepsIdPublic
        );
    },
    UpdateWorkOrder(model) {
        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/updateWorkOrder',
            model
        );
    },
    createProgressSchedulingConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/createProgressSchedulingConfig',
            model
        );
    },
    getProgressSchedulingList() {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getProgressSchedulingList'
        );
    },
    getUnusedProgressForSchedulingConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getUnusedProgressForSchedulingConfig'
        );
    },
    deleteProgressSchedulingConfig(progressSchedulingConfigId) {
        return Vue.prototype.$axiosMainClient.delete(
            'Scheduling/deleteProgressSchedulingConfig?progressSchedulingConfigId=' +
                progressSchedulingConfigId
        );
    },
    getProgressSchedulingConfig(progressSchedulingConfigId) {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getProgressSchedulingConfig?progressSchedulingConfigId=' +
                progressSchedulingConfigId
        );
    },
    updateProgressSchedulingConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/updateProgressSchedulingConfig',
            model
        );
    },
    getSchedulingThresholdsConfig() {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getSchedulingThresholdsConfig'
        );
    },
    saveSchedulingThresholdsConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/saveSchedulingThresholdsConfig',
            model
        );
    },
    getProgressScheduling(progressId, page, today) {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getProgressScheduling?progressId=' +
                progressId +
                '&page=' +
                page +
                '&today=' +
                today
        );
    },
    schedulingLockPositions(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/lockPositions',
            model
        );
    },
    schedulingLockPosition(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/lockPosition',
            model
        );
    },
    schedulingUnlockPosition(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/unlockPosition',
            model
        );
    },
    schedulingHideWorkOrderForProgress(progressId, workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/hideWorkOrderForProgress',
            {
                progressId,
                workOrderIdPublic,
            }
        );
    },
    saveSchedulingWorkOrderNote(progress, workOrderIdPublic, note) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/saveSchedulingWorkOrderNote',
            {
                progress,
                workOrderIdPublic,
                note,
            }
        );
    },
    createMergedProgressSchedulingConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/createMergedProgressSchedulingConfig',
            model
        );
    },
    getMergedProgressSchedulingConfigList() {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getMergedProgressSchedulingConfigList'
        );
    },
    getMergedProgressSchedulingUserList() {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getMergedProgressSchedulingUserList'
        );
    },
    deleteMergedProgressSchedulingConfig(smpId) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/deleteMergedProgressSchedulingConfig?id=' + smpId
        );
    },
    getMergedProgressScheduling(mpId, page, today) {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getMergedProgressScheduling?id=' +
                mpId +
                '&page=' +
                page +
                '&today=' +
                today
        );
    },
    getMergedProgressSchedulingConfig(mpscId) {
        return Vue.prototype.$axiosMainClient.get(
            'Scheduling/getMergedProgressSchedulingConfig?id=' + mpscId
        );
    },
    updateMergedProgressSchedulingConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/updateMergedProgressSchedulingConfig',
            model
        );
    },
    schedulingMergedProgressLockedPosition(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/mergedProgressLockedPosition',
            model
        );
    },
    schedulingMergedProgressUnlockPosition(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/mergedProgressUnlockPosition',
            model
        );
    },
    schedulingMergedProgressLockPositions(model) {
        return Vue.prototype.$axiosMainClient.post(
            'Scheduling/mergedProgressLockPositions',
            model
        );
    },
    deleteUserEvent(userEventId) {
        return Vue.prototype.$axiosMainClient.delete(
            'Appointment/deleteUserEvent?userEventId=' + userEventId
        );
    },
    getProgressAppointmentDate(progressId, workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'Appointment/getProgressAppointmentDate?progressId=' +
                progressId +
                '&workOrderIdPublic=' +
                workOrderIdPublic
        );
    },
    getProgressAppointmentDateV1_1(stepId, workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            '/v1.1/Appointment/getProgressAppointmentDate?stepId=' +
                stepId +
                '&workOrderIdPublic=' +
                workOrderIdPublic
        );
    },
    getDeliveryDate(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getDeliveryDate?workOrderIdPublic=' + workOrderIdPublic
        );
    },
    getInstallationDate(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getInstallationDate?workOrderIdPublic=' +
                workOrderIdPublic
        );
    },
    createWorkOrderGroup(groupName) {
        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/createWorkOrderGroup',
            { name: groupName }
        );
    },
    getWorkOrderGroups(groupName) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrderGroups?name=' + groupName
        );
    },
    getWorkOrders(filters) {
        var param = this.getWorkOrderFilters(filters);

        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrders?' + param
        );
    },
    getWorkOrdersNoWorkOrderProgress(workOrdersId, filters) {
        var param = this.getWorkOrderFilters(filters);

        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/getWorkOrdersNoWorkOrderProgress?' + param,
            workOrdersId
        );
    },
    getWorkOrderProgress(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/' + workOrderIdPublic + '/getWorkOrderProgress'
        );
    },
    workOrderBulkChange(data) {
        return Vue.prototype.$axiosMainClient.post(
            'WorkOrder/bulkChange',
            data
        );
    },
    deleteWorkOrders(workOrdersIdPublic) {
        return Vue.prototype.$axiosMainClient.delete('WorkOrder/batch', {
            data: workOrdersIdPublic,
        });
    },
    getWorkOrdersCount(filters) {
        var param = this.getWorkOrderFilters(filters);

        return Vue.prototype.$axiosMainClient.get(
            'WorkOrder/getWorkOrdersCount?' + param
        );
    },
    getQueryString(filters) {
        if (!filters) return '';

        return Object.keys(filters)
            .map((key) => {
                if (filters[key] != null && filters[key]?.length != 0) {
                    if (Array.isArray(filters[key])) {
                        let listArray = [];
                        for (var item of filters[key]) {
                            listArray.push(key + '=' + item);
                        }
                        return listArray.join('&');
                    } else {
                        return key + '=' + filters[key];
                    }
                } else {
                    return '';
                }
            })
            .filter((x) => {
                return x != '';
            })
            .join('&');
    },
    getWorkOrderFilters(filters) {
        if (!filters) return '';

        return Object.keys(filters)
            .map((key) => {
                if (filters[key] != null && filters[key]?.length != 0) {
                    if (Array.isArray(filters[key])) {
                        let listArray = [];
                        for (var item of filters[key]) {
                            listArray.push(key + '=' + item);
                        }
                        return listArray.join('&');
                    } else {
                        return key + '=' + filters[key];
                    }
                } else {
                    return '';
                }
            })
            .filter((x) => {
                return x != '';
            })
            .join('&');
    },
    getInstallersCapacities(installersIdPublic) {
        return Vue.prototype.$axiosMainClient.post(
            'Installers/capacities',
            installersIdPublic
        );
    },
    getIsMoveLaterProgress(stepId) {
        return Vue.prototype.$axiosMainClient.get(
            'progress/isMoveLaterStep?stepId=' + stepId
        );
    },
    getFiltersForDateRange(model) {
        let queryString =
            'startDate=' +
            Dayjs(model.startDate).format('YYYY-MM-DD') +
            '&endDate=' +
            Dayjs(model.endDate).format('YYYY-MM-DD');
        queryString += '&intervalType=' + model.intervalType;

        model.workOrderTypesIdPublic.forEach((x) => {
            queryString += '&workOrderTypesIdPublic=' + x;
        });

        model.measureTypes.forEach((x) => {
            queryString += '&measureTypes=' + x;
        });

        return queryString;
    },
    getWorkOrderProgressValuesForDateRange(model) {
        let queryString = this.getFiltersForDateRange(model);

        model.progressId.forEach((x) => {
            queryString += '&progressId=' + x;
        });

        return Vue.prototype.$axiosMainClient.get(
            'workOrder/getWorkOrderProgressValuesForDateRange?' + queryString
        );
    },
    getProgressCapacitiesForDateRange(model) {
        return Vue.prototype.$axiosMainClient.post(
            'workOrder/getProgressCapacitiesForDateRange',
            model
        );
    },
    getInstallationCapacitiesForDateRange(model) {
        return Vue.prototype.$axiosMainClient.post(
            'workOrder/getInstallationCapacitiesForDateRange',
            model
        );
    },
    getWorkOrderInstallationValuesForDateRange(model) {
        // eslint-disable-next-line no-unused-vars
        let queryString = this.getFiltersForDateRange(model);

        // eslint-disable-next-line no-unused-vars
        model.installersIdPublic.forEach((x) => {
            queryString += '&installersIdPublic=' + x;
        });

        return Vue.prototype.$axiosMainClient.get(
            'workOrder/getWorkOrderInstallationValuesForDateRange?' +
                queryString
        );
    },
    getProgressAppointmentsWithValues(progressAppointmentIds) {
        return Vue.prototype.$axiosMainClient.post(
            'appointment/getProgressAppointmentsWithValues',
            progressAppointmentIds
        );
    },
    setProgressAppointmentsDates(model) {
        return Vue.prototype.$axiosMainClient.post(
            'appointment/setProgressAppointmentsDates',
            model
        );
    },
    createCapacityDashboard(name) {
        return Vue.prototype.$axiosMainClient.post(
            'dashboard/createCapacityDashboard',
            { name }
        );
    },
    getCapacityDashboards() {
        return Vue.prototype.$axiosMainClient.get(
            'dashboard/getCapacityDashboards'
        );
    },
    removeCapacityDashboard(id) {
        return Vue.prototype.$axiosMainClient.delete(
            'dashboard/removeCapacityDashboard?id=' + id
        );
    },
    createCapacityChart(model) {
        return Vue.prototype.$axiosMainClient.post(
            'dashboard/createCapacityChart',
            model
        );
    },
    getCapacityCharts(dashboardId) {
        return Vue.prototype.$axiosMainClient.get(
            'dashboard/getCapacityCharts?dashboardId=' + dashboardId
        );
    },
    setChartValueTypeSelected(model) {
        return Vue.prototype.$axiosMainClient.post(
            'dashboard/setChartValueTypeSelected',
            model
        );
    },
    getValueTypesSelected(capacityChartId) {
        return Vue.prototype.$axiosMainClient.get(
            'dashboard/getValueTypesSelected?capacityChartId=' + capacityChartId
        );
    },
    removeCapacityChart(capacityChartId) {
        return Vue.prototype.$axiosMainClient.delete(
            'dashboard/removeCapacityChart?id=' + capacityChartId
        );
    },
    updateCapacityChartsOrder(model) {
        return Vue.prototype.$axiosMainClient.post(
            'dashboard/setCapacityChartsOrder',
            model
        );
    },
    getCurrentBuildNumberInfo() {
        return Vue.prototype.$axiosMainClient.get(
            'utilities/getBuildNumberInfo'
        );
    },
    setUserLastSeenBuildNumber() {
        return Vue.prototype.$axiosMainClient.post(
            'utilities/setUserLastSeenBuildNumber'
        );
    },
    getLastPublicationNotesPdf(locale) {
        return Vue.prototype.$axiosMainClient.get(
            'utilities/getLastPublicationNotesPdf?locale=' + locale,
            {
                responseType: 'blob',
            }
        );
    },
    getProgressMaintenances(includeProgress) {
        return Vue.prototype.$axiosMainClient.get(
            'progressmaintenances?includeprogress=' + includeProgress
        );
    },
    getMergedProgressMaintenances(includeProgress) {
        return Vue.prototype.$axiosMainClient.get(
            'mergedprogressmaintenances?includeprogress=' + includeProgress
        );
    },
    createProgressMaintenanceConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'progressmaintenances',
            model
        );
    },
    createMergedProgressMaintenanceConfig(model) {
        return Vue.prototype.$axiosMainClient.post(
            'mergedprogressmaintenances',
            model
        );
    },
    editProgressMaintenanceConfig(model) {
        return Vue.prototype.$axiosMainClient.put(
            'progressmaintenances/' + model.id,
            model
        );
    },
    editMergedProgressMaintenanceConfig(model) {
        return Vue.prototype.$axiosMainClient.put(
            'mergedprogressmaintenances/' + model.id,
            model
        );
    },
    getProgressMaintenance(id) {
        return Vue.prototype.$axiosMainClient.get('progressmaintenances/' + id);
    },
    getMergedProgressMaintenance(id) {
        return Vue.prototype.$axiosMainClient.get(
            'mergedprogressmaintenances/' + id
        );
    },
    startProgressMaintenance(id, model) {
        return Vue.prototype.$axiosMainClient.post(
            'progressmaintenances/' + id + '/started',
            model
        );
    },
    startMergedProgressMaintenance(id, model) {
        return Vue.prototype.$axiosMainClient.post(
            'mergedprogressmaintenances/' + id + '/started',
            model
        );
    },
    getOngoingMaintenances(page) {
        return Vue.prototype.$axiosMainClient.get(
            'ongoingmaintenances/?page=' + page
        );
    },
    deleteMergedProgressMaintenance(maintenanceId) {
        return Vue.prototype.$axiosMainClient.delete(
            'mergedprogressmaintenances/' + maintenanceId
        );
    },
    deleteProgressMaintenance(maintenanceId) {
        return Vue.prototype.$axiosMainClient.delete(
            'progressmaintenances/' + maintenanceId
        );
    },
    createBlockerEvent(model) {
        return Vue.prototype.$axiosMainClient.post('blockerevents/', model);
    },
    updateBlockerEvent(model) {
        return Vue.prototype.$axiosMainClient.put('blockerevents/', model);
    },
    getBlockerEvents(start, end, mapping, progress, installers, shippers) {
        return Vue.prototype.$axiosMainClient.get(
            'v1.1/blockerevents/?startDate=' +
                start +
                '&endDate=' +
                end +
                '&mapping=' +
                mapping +
                (progress.length != 0 ? '&progressIds=' : '') +
                progress.join('&progressIds=') +
                (installers.length != 0 ? '&installerIds=' : '') +
                installers.join('&installerIds=') +
                (shippers.length != 0 ? '&shipperIds=' : '') +
                shippers.join('&shipperIds=')
        );
    },
    postGetBlockerEvents(start, end, mapping, steps, installers, shippers) {
        return Vue.prototype.$axiosMainClient.post('v1.2/blockerevents/', {
            startDate: start,
            endDate: end,
            mapping: mapping,
            stepIds: steps,
            installerIds: installers,
            shipperIds: shippers,
        });
    },
    postGetBlockerEvents3(model) {
        return Vue.prototype.$axiosMainClient.post(
            'v1.3/blockerevents/',
            model
        );
    },
    deleteEvent(id) {
        return Vue.prototype.$axiosMainClient.delete('events/' + id);
    },
    getWorkOrderStepValuesByWorkOrderIdPublic(workorderidpublic) {
        return Vue.prototype.$axiosMainClient.get(
            'workorders/' + workorderidpublic + '/workorderstepvalues'
        );
    },
    getSideBarAlerts() {
        return Vue.prototype.$axiosMainClient.get(`alerts?group=sidebar`);
    },
    sendDeliveryEmail(model) {
        return Vue.prototype.$axiosMainClient.post(
            'email/sendDeliveryEmail',
            model
        );
    },
    sendInstallationEmail(model) {
        return Vue.prototype.$axiosMainClient.post(
            'email/sendInstallationEmail',
            model
        );
    },
};
